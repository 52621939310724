@import styles/sass/abstracts

.loginTemplate
  padding: 100px 0
  +res-sm-max
    padding: 30px 0

.register
  +typoTextSm
  text-align: center
  margin: 20px 0
  a
    font-weight: 600
    color: $accent-default

.social-button
  width: 100%

.socialLoginNotification
  text-align: center
  margin-top: 20px

.head
  margin-bottom: 30px

.title
  +typoH2Serif

.description
  margin-top: 12px
  +typoTextSm
  +customContent


